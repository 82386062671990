<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  created() {
    this.setView();
    window.addEventListener('resize', this.setView);
  },
  methods: {
    setView() {
      const WIDTH = 1960; // 如果是尺寸的设计稿在这里修改
      const oHtml = document.getElementsByTagName('html')[0];
      const width = Math.max(960, window.innerWidth);
      // document.documentElement.style.fontSize = `${(100 * width) / WIDTH}px`;
      oHtml.style.fontSize = `${(100 * width) / WIDTH}px`;
    },
  },
};
</script>

<style lang="less">
#app {
  font-family: Microsoft YaHei, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #0066dd;
}
body {
  margin: 0 auto;
  padding: 0;
  a {
    text-decoration: none;
  }
  min-width: 960px;
  max-width: 1920px;
  font-size: 0;
}
* {
  box-sizing: border-box;
}
@media screen and (min-width: 980px) {
  body {
    overflow-x: hidden;
  }
}
@media screen and (max-width: 980px) {
  body {
    min-width: 960px;
  }
}
.color-button {
  background: linear-gradient(to right, #2288ff, #0238df) !important;
}
</style>
