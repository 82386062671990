export const routeUrls = {
  home: '/',
  mineSolution: '/solution/smart-mine-solution',
  campusSolution: '/solution/smart-campus-solution',
  aiSolution: '/solution/ai-solution',
  systemSolution: '/solution/trust-system-solution',
  educationSolution: '/solution/education-train-solution',
  mineBusiness: '/business/mine-research-center',
  solutionBusiness: '/business/solution-center',
  educationBusiness: '/business/education-center',
  consultationBusiness: '/business/consultation-center',
  about: '/about',
  news: '/news',
};

export const homeMenu = {
  title: '首页',
  url: routeUrls.home,
};

export const aboutMenu = {
  title: '关于千沐',
  url: routeUrls.about,
};

export const newsMenu = {
  title: '新闻资讯',
  url: routeUrls.news,
};

export const serviceMenu = {
  title: '产品服务',
  child: [
    {
      title: '智能矿山解决方案',
      url: routeUrls.mineSolution,
    },
    {
      title: '智慧校园解决方案',
      url: routeUrls.campusSolution,
    },
    {
      title: '人工智能产教融合',
      url: routeUrls.aiSolution,
    },
    {
      title: '数字安全体系',
      url: routeUrls.systemSolution,
    },
    // {
    //   title: '教育培训解决方案',
    //   url: routeUrls.educationSolution,
    // },
  ],
};

export const businessMenu = {
  title: '主要业务',
  child: [
    {
      title: '智能矿山研究中心',
      url: routeUrls.mineBusiness,
    },
    {
      title: '解决方案中心',
      url: routeUrls.solutionBusiness,
    },
    {
      title: '教育培训中心',
      url: routeUrls.educationBusiness,
    },
    {
      title: '技术咨询中心',
      url: routeUrls.consultationBusiness,
    },
  ],
};

export const modalExtra = '环境人员复杂，整体空间大，环境复杂，入驻和到访的人员数量庞大，传统技术手段无法应对当前安保新需求。管理亟待提升，管理人员无法及时掌握出入人员数据，难以针对发现的问题作出及时应对。环境人员复杂，整体空间大，环境复杂，入驻和到访的人员数量庞大，传统技术手段无法应对当前安保新需求。管理亟待提升，管理人员无法及时掌握出入人员数据，难以针对发现的问题作出及时应对。环境人员复杂，整体空间大，环境复杂，入驻和到访的人员数量庞大，传统技术手段无法应对当前安保新需求。管理亟待提升，管理人员无法及时掌握出入人员数据，难以针对发现的问题作出及时应对。环境人员复杂，整体空间大，环境复杂，入驻和到访的人员数量庞大，传统技术手段无法应对当前安保新需求。管理亟待提升，管理人员无法及时掌握出入人员数据，难以针对发现的问题作出及时应对。环境人员复杂，整体空间大，环境复杂，入驻和到访的人员数量庞大，传统技术手段无法应对当前安保新需求。管理亟待提升，管理人员无法及时掌握出入人员数据，难以针对发现的问题作出及时应对。';
