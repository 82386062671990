<template>
  <div class="about">
    <div class="words">以人工智能技术为基石</div>
    <div class="words">为矿山智能化及AI教育持续赋能</div>
    <div class="btn-wrapper">
      <div class="about-btn color-button" @click="goto()">关于千沐</div>
    </div>
    <div class="items-wrapper">
      <div class="items">
        <div class="item" v-for="(item, index) in aboutItems" :key="index">
          <div class="number">{{ item.number }}</div>
          <img :src="item.img" alt="" />
          <div class="name">{{ item.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { routeUrls } from '../utils/constants';
import about1 from '../assets/images/home/about-1.png';
import about2 from '../assets/images/home/about-2.png';
import about3 from '../assets/images/home/about-3.png';
import about4 from '../assets/images/home/about-4.png';

export default {
  name: 'HomeAbout',
  props: {},
  data() {
    return {
      aboutItems: [
        {
          name: '五大行业',
          number: 5,
          img: about1,
        },
        {
          name: '服务院校',
          number: '100+',
          img: about2,
        },
        {
          name: '教培用户',
          number: '10万+',
          img: about3,
        },
        {
          name: '服务企业',
          number: '1000+',
          img: about4,
        },
      ],
    };
  },
  methods: {
    goto() {
      this.$router.push({ path: routeUrls.about });
    },
  },
};
</script>
<style scoped lang="less">
.about {
  height: @size910;
  min-height: 455px;
  width: 100%;
  overflow: hidden;
  position: relative;
  background: #f5f5f5;
  padding-top: 122px;
  text-align: center;
  .words {
    font-size: @size40;
    color: #333333;
    line-height: 1.35;
  }
  .btn-wrapper {
    display: flex;
    justify-content: center; /* 水平居中 */
    margin-top: 100px;
    .about-btn {
      width: 260px;
      height: 70px;
      border-radius: 70px;
      text-align: center;
      line-height: 70px;
      font-size: 30px;
      font-weight: 700;
      color: #ffffff;
      cursor: pointer;
    }
  }
  .items-wrapper {
    margin-top: 100px;
    display: flex;
    justify-content: center;
    .items {
      display: flex;
      width: @size1200;
      justify-content: space-between;
    }
    .item {
      width: 280px;
      height: 220px;
      background: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center; /* 水平居中 */
      align-items: center; /* 垂直居中 */
      img {
        width: 86px;
        height: 86px;
        height: auto;
      }
      .number {
        font-size: 36px;
        font-weight: 700;
        text-align: center;
        color: #0066dd;
        line-height: 1;
        margin-bottom: 14px;
      }
      .name {
        font-size: 22px;
        text-align: center;
        color: #0066dd;
        margin-top: 14px;
      }
    }
    .item:last-child {
      margin-right: 0px;
    }
  }
}
</style>
