<template>
  <div class="footer">
    <div class="items-wrapper">
      <div class="items">
        <div class="item" v-for="(item, index) in items" :key="index">
          <div class="title">{{ item.title }}</div>
          <div
            class="child"
            v-for="(child, childIndex) in item.child"
            :key="childIndex"
            @click="goto(child)"
          >
            <span v-if="!child.href">{{ child.title }}</span>
            <span v-if="child.href"><a :href="child.href" target="_blank">{{child.title}}</a></span>
          </div>
        </div>
      </div>
    </div>

    <div class="divider"></div>
    <div class="auth">
      <div>版权所有2021-2022千沐科技版权所有 沪ICP备2021024293号</div>
    </div>
  </div>
</template>

<script>
import { serviceMenu, businessMenu } from '../utils/constants';

export default {
  name: 'Footer',
  props: {},
  data() {
    return {
      items: [
        businessMenu,
        serviceMenu,
        {
          title: '联系我们',
          child: [
            {
              title: '加入我们：job@coalchinajy.com',
            },
            {
              title: '商务合作：business@coalchinajy.com',
            },
            {
              title: '技术支持：ecc@coalchinajy.com',
            },
            {
              title: '渠道合作：400-858-6686',
            },
          ],
        },
        {
          title: '友情链接',
          child: [
            {
              title: '中华人民共和国应急管理部官网',
              href: 'https://www.mem.gov.cn/',
            },
            {
              title: '国家矿山安全监察局官网',
              href: 'https://www.chinamine-safety.gov.cn/',
            },
            {
              title: '中国煤炭工业协会官网',
              href: 'http://www.zgmtgyxh.org.cn/',
            },
            {
              title: '中国煤炭教育赋能云平台',
              href: 'https://www.coalchinajy.com/',
            },
          ],
        },
      ],
    };
  },
  methods: {
    goto(item) {
      const { url } = item;
      if (url) {
        this.$router.push({ path: url });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.footer {
  background: #393d41;
  text-align: left;
  width: 100%;
}
.items-wrapper {
  display: flex;
  justify-content: center;
  .items {
    margin-top: @size54;
    width: @size1200;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .item {
      // flex: 0 0 25%;

      .title {
        color: #fff;
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 20px;
      }
      .child {
        cursor: pointer;
        color: #e6e6e6;
        font-size: 18px;
        line-height: 3;
        a {
          color: #e6e6e6;
        }
      }
    }
  }
}

.divider {
  margin-top: 40px;
  height: 1px;
  background: #999;
  width: 100%;
}
.auth {
  display: flex;
  justify-content: center;
  height: 80px;
  div {
    width: 1200px;
    font-size: 18px;
    color: #e6e6e6;
    text-align: left;
    line-height: 80px;
  }
}
</style>
