<template>
  <div class="service">
    <div class="title">产品服务</div>
    <div class="divider"></div>
    <div class="wrapper">
      <el-carousel
        height="4.6rem"
        indicator-position="outside"
        :autoplay="false"
      >
        <el-carousel-item>
          <div class="inner">
            <div
              class="item"
              v-for="(item, index) in serviceItems.slice(0, 3)"
              :key="index"
            >
              <img :src="item.img" alt="" />
              <div class="front">
                <div class="service-title">{{ item.title }}</div>
                <div class="service-infos">
                  <div
                    class="service-info"
                    v-for="(info, infoIndex) in item.infos"
                    :key="infoIndex"
                  >
                    {{ info }}
                  </div>
                </div>
                <div class="more color-button" @click="goto(item)">了解更多</div>
              </div>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="inner">
            <div
              class="item"
              v-for="(item, index) in serviceItems.slice(2, 4)"
              :key="index"
            >
              <img :src="item.img" alt="" />
              <div class="front">
                <div class="service-title">{{ item.title }}</div>
                <div class="service-infos">
                  <div
                    class="service-info"
                    v-for="(info, infoIndex) in item.infos"
                    :key="infoIndex"
                  >
                    {{ info }}
                  </div>
                </div>
                <div class="more color-button" @click="goto(item)">了解更多</div>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
import server1 from '../assets/images/home/service-1.jpg';
import server2 from '../assets/images/home/service-2.jpg';
import server3 from '../assets/images/home/service-3.jpg';
import server4 from '../assets/images/home/service-4.jpg';
import { routeUrls } from '../utils/constants';

export default {
  name: 'HomeSolution',
  props: {},
  // created() {
  //   this.getHeight();
  //   window.addEventListener('resize', this.getHeight);
  // },
  // destroyed() {
  //   window.removeEventListener('resize', this.getHeight);
  // },
  data() {
    return {
      height: 0,
      serviceItems: [
        {
          title: '智慧矿山解决方案',
          infos: ['致力于提升矿山智能化水平', '以科技进步推动煤矿安全及高效生产'],
          img: server1,
          url: routeUrls.mineSolution,
        },
        {
          title: '智慧校园解决方案',
          infos: ['夯实学校信息基础与智能管理', '进一步提升校园数字化水平'],
          img: server2,
          url: routeUrls.campusSolution,
        },
        {
          title: '人工智能产教融合',
          infos: ['以创新实训助力智能化人才培养', '打造完整AI产教融合体系'],
          img: server3,
          url: routeUrls.aiSolution,
        },
        {
          title: '数字安全体系解决方案',
          infos: ['助力企业实现业务的数字身份', '为企业全程电子化及网络安全保驾护航'],
          img: server4,
          url: routeUrls.systemSolution,
        },
      ],
    };
  },
  methods: {
    goto(item) {
      const { url } = item;
      if (url) {
        this.$router.push({ path: url });
      }
    },
    getHeight() {
      const width = Math.max(window.innerWidth, 960);
      this.height = `${(460 / 1920) * width}px`;
    },
  },
};
</script>
<style scoped lang="less">
// @import '../assets/variables';
// @size910: @size910;
.service {
  // background: #5885b9;
  background-image: url(../assets/images/home/service-back.jpg);
  background-size: cover;
  height: @size910;
  min-height: 455px;
  width: 100%;
  overflow: hidden;
  position: relative;
  text-align: center;
  .title {
    margin-top: @size80;
    font-size: @size40;
    font-weight: 700;
    text-align: center;
    color: #ffffff;
  }
  .divider {
    display: inline-block;
    text-align: center;
    width: @size60;
    min-width: 30px;
    height: @size4;
    min-height: 2px;
    background: #ffffff;
    margin-top: 30px;
  }
  .wrapper {
    width: 100%;
    overflow: hidden;
    padding-left: @size150;
    padding-right: @size30;
    margin-top: @size100;
    .inner {
      min-width: 2500px;
      overflow: hidden;
      position: relative;
      .item {
        float: left;
        width: @size780;
        min-width: 390px;
        height: @size458;
        min-height: 230px;
        border-radius: @size10;
        overflow: hidden;
        margin-right: @size50;
        background: #000;
        position: relative;
        img,
        .front {
          width: 100%;
          overflow: hidden;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
        .front {
          background: rgba(0, 0, 0, 0.5);
          z-index: 100;
        }
        .service-title {
          margin-left: @size40;
          margin-top: @size148;
          font-size: @size32;
          font-weight: 700;
          text-align: left;
          color: #ffffff;
          margin-bottom: @size40;
        }
        .service-info {
          border-left: 2px solid #fff;
          margin-left: @size40;
          padding-left: @font16;
          font-size: @size22;
          color: #fff;
          text-align: left;
        }
        .more {
          width: @size160;
          min-width: 80px;
          height: @size50;
          min-height: 25px;
          background: #0066dd;
          border-radius: @size25;
          text-align: center;
          line-height: @size50;
          font-size: @size20;
          font-weight: 700;
          color: #ffffff;
          margin-left: @size40;
          margin-top: @size70;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
<style lang="less">
.service {
  .el-carousel__indicator--horizontal {
    padding-top: @size100 !important;
  }
  .el-carousel__indicators--outside button {
    width: @size120;
    min-width: 60px;
    height: @size10;
    min-height: 5px;
    border-radius: 5px;
  }
  .el-carousel__arrow {
    display: none !important;
  }
}
</style>
