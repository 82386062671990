<template>
  <div class="header" :class="headerClass">
    <a href="/">
      <img
        src="../assets/images/logo-dark.png"
        alt="logo"
        class="logo"
        v-if="theme === 'black' || inScroll"
      />
      <img
        src="../assets/images/logo-white.png"
        alt="logo"
        class="logo"
        v-if="theme === 'white' && !inScroll"
      />
    </a>

    <div class="nav" :class="theme">
      <div
        class="item"
        v-for="(item, index) in menu"
        :key="index"
        :class="currentIndex == index ? 'active' : ''"
        @click="goto(item)"
      >
        {{ item.title }}
        <div class="menu" v-if="item.child">
          <div
            class="menu-item"
            v-for="(it, itIndex) in item.child"
            :key="itIndex"
            :class="
              currentIndex == index && childIndex == itIndex
                ? 'child-active'
                : ''
            "
            @click="goto(it)"
          >
            {{ it.title }}
          </div>
        </div>
      </div>
    </div>

    <div class="phone" :class="theme">
      <img
        src="../assets/images/icon-tel-dark.png"
        alt="logo"
        class="phone-icon"
        v-if="theme === 'black' || inScroll"
      />
      <img
        src="../assets/images/icon-tel-white.png"
        alt="logo"
        class="phone-icon"
        v-if="theme === 'white' && !inScroll"
      />
      <a class="phone-number">400-858-6686</a>
    </div>
  </div>
</template>

<script>
import {
  homeMenu,
  aboutMenu,
  newsMenu,
  serviceMenu,
  businessMenu,
} from '../utils/constants';

export default {
  name: 'Header',
  props: {
    currentIndex: Number,
    childIndex: Number,
    theme: String,
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  data() {
    return {
      menu: [homeMenu, businessMenu, serviceMenu, newsMenu, aboutMenu],
      headerClass: '',
      inScroll: false,
    };
  },
  methods: {
    goto(item) {
      const { url } = item;
      if (url) {
        this.$router.push({ path: url });
      }
    },
    handleScroll() {
      const { scrollY } = window;
      if (scrollY > 0) {
        this.inScroll = true;
        this.headerClass = this.theme === 'black' ? 'scroll shadow' : 'scroll';
      } else {
        this.inScroll = false;
        this.headerClass = '';
      }
      // Any code to be executed when the window is scrolled
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.header {
  height: @size90;
  min-height: 45px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  padding-left: @size150;
  padding-right: @size150;
  text-align: left;
  line-height: 5;
  font-size: @font16;
  width: 100%;
  min-width: 1920px;
  border-bottom: solid 1px rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.shadow {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
}
.scroll {
  background: #fff;
  .item {
    color: #333 !important;
  }
  .active {
    color: #0066dd !important;
  }
  .phone {
    .phone-number {
      color: #0066dd !important;
    }
  }
}
.logo {
  width: 260px;
  vertical-align: middle;
}
.black {
  color: #333333;
  .active {
    color: #0066dd;
  }
  a {
    color: #333;
  }
}
.white {
  color: #fff;
  a {
    color: #333;
  }
}
.nav {
  line-height: @size90;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .item {
    margin-left: 80px;
    cursor: pointer;
    position: relative;
    font-size: 22px;
    &:hover {
      .menu {
        display: flex;
      }
    }
    .menu {
      display: none;
      position: absolute;
      left: 0;
      flex-direction: column;
      padding: @size20;
      font-size: 18px;
      line-height: 2.5;
      background: rgba(255, 255, 255, 0.8);
      padding-left: 20px;
      padding-right: 20px;
      white-space: nowrap;
      color: #666;
      font-weight: 400;
      .menu-item {
        cursor: pointer;
        &:hover {
          color: #0066dd;
        }
      }
    }
    .child-active {
      color: #0066dd;
      font-weight: 700;
    }
  }
  .active {
    font-weight: 700;
  }
}
.phone {
  margin-left: 100px;
  .phone-icon {
    width: 36px;
    height: 36px;
    vertical-align: middle;
    margin-right: 20px;
  }
  .phone-number {
    font-size: 28px;
    color: #0066dd;
    line-height: 90px;
    vertical-align: middle;
  }
}
.white {
  .phone-number {
    color: #fff;
  }
}
</style>
