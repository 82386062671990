<template>
  <div class="partner">
    <div class="title">合作伙伴</div>
    <div class="divider"></div>
    <div class="wrapper">
      <div class="inner">
        <div class="item" v-for="(item, index) in partnerItems" :key="index">
          <img :src="item.img" alt="" />
        </div>
      </div>
    </div>
    <div class="more-wrapper">
      <div class="more color-button" @click="goto()">查看更多</div>
    </div>
  </div>
</template>

<script>
import logo1 from '../assets/images/home/logo1.jpg';
import logo2 from '../assets/images/home/logo2.jpg';
import logo3 from '../assets/images/home/logo3.jpg';
import logo4 from '../assets/images/home/logo4.jpg';
import logo5 from '../assets/images/home/logo5.jpg';
import logo6 from '../assets/images/home/logo6.jpg';

export default {
  name: 'HomePartner',
  props: {},
  data() {
    return {
      partnerItems: [
        {
          img: logo1,
        },
        {
          img: logo2,
        },
        {
          img: logo3,
        },
        {
          img: logo4,
        },
        {
          img: logo5,
        },
        {
          img: logo6,
        },
      ],
    };
  },
  methods: {
    goto() {
      this.$router.push({ name: 'About' });
    },
  },
};
</script>
<style scoped lang="less">
.partner {
  height: @size800;
  min-height: 400px;
  width: 100%;
  overflow: hidden;
  position: relative;
  text-align: center;
  border: 1px solid #999999;
  .title {
    margin-top: @size80;
    font-size: @size40;
    font-weight: 700;
    text-align: center;
    color: #666666;
  }
  .divider {
    display: inline-block;
    text-align: center;
    width: @size60;
    min-width: 30px;
    height: @size4;
    min-height: 2px;
    background: #0066dd;
    margin-top: 30px;
  }
  .wrapper {
    margin-top: @size100;
    justify-content: center;
    display: flex;
    .inner {
      display: inline-block;
      overflow: hidden;
      position: relative;
      width: @size1200;
      min-width: 600px;
      text-align: center;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .item {
        width: @size300;
        min-width: 150px;
        // height: @size100;
        border: 1px solid #999;
        margin-bottom: @size40;
        flex: 0 0 30%;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
  .more-wrapper {
    display: flex;
    justify-content: center; /* 水平居中 */
    margin-top: @size100;
    .more {
      width: @size160;
      min-width: 80px;
      height: @size50;
      min-height: 25px;
      background: #0066dd;
      border-radius: @size25;
      text-align: center;
      line-height: @size50;
      font-size: @size20;
      font-weight: 700;
      color: #ffffff;
      cursor: pointer;
    }
  }
}
</style>
