<template>
  <div class="business">
    <div class="title">主要业务</div>
    <div class="divider"></div>
    <div class="wrapper">
      <div class="inner">
        <div class="top" @click="goto('mine')">
          <div class="top-color"></div>
          <div class="top-item">
            <div class="top-first-line">
              <img :src="business1" alt="logo" />
              <div class="top-title">千沐智能矿山研究中心</div>
            </div>
            <div class="top-intro">
              千沐科技联合中国科学院上海高等研究院、行业院校等共同发起的新型研发机构，致力于机制体制创新、先试先行，以科技进步助力煤矿智能化
            </div>
          </div>

          <div class="more" @click="goto('mine')">了解更多 ></div>
        </div>
        <div class="items">
          <div
            class="item"
            v-for="(item, index) in businessItems"
            :key="index"
            @click="goto(item.url)"
          >
            <div class="first-line">
              <img :src="item.img" alt="icon" />
              <div class="right">
                <div class="line-title">{{ item.title }}</div>
                <div class="line-en">{{ item.en }}</div>
              </div>
            </div>
            <div class="intro" v-if="item.info">{{ item.info }}</div>
            <div class="more" @click="goto(item.url)">了解更多 ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { routeUrls } from '../utils/constants';
import business1 from '../assets/images/home/business-1.png';
import business2 from '../assets/images/home/business-2.png';
import business3 from '../assets/images/home/business-3.png';
import business4 from '../assets/images/home/business-4.png';

export default {
  name: 'HomeBusiness',
  props: {},
  data() {
    return {
      business1,
      businessItems: [
        {
          title: '解决方案中心',
          info: '紧贴智能化趋势，以客户实际需求为起点，针对智能矿山及智慧校园提供贴合行业实际需求的解决方案',
          url: routeUrls.solutionBusiness,
          img: business2,
        },
        {
          title: '教育培训中心',
          url: routeUrls.educationBusiness,
          img: business3,
          info: '基于“赋能云平台”，积极发展“互联网+教育”，切实培养中国煤炭行业人才，打通各终端，提供全场景自主式教育培训平台',
        },
        {
          title: '技术咨询中心',
          url: routeUrls.consultationBusiness,
          img: business4,
          info: '以智能矿山研究中心专家团队为基石，通过解决方案中心输出适合企业的具体解决方案，提供智能化改进的方向和技术咨询',
        },
      ],
    };
  },
  methods: {
    goto(url) {
      if (url === 'mine') {
        this.$router.push({ path: routeUrls.mineBusiness });
      } else {
        this.$router.push({ path: url });
      }
    },
  },
};
</script>
<style scoped lang="less">
.business {
  height: @size910;
  min-height: 450px;
  width: 100%;
  overflow: hidden;
  position: relative;
  text-align: center;
  background-image: url(../assets/images/home/business-back.jpg);
  background-size: cover;
  .title {
    margin-top: @size80;
    font-size: @size40;
    font-weight: 700;
    text-align: center;
    color: #666666;
  }
  .divider {
    display: inline-block;
    text-align: center;
    width: @size60;
    min-width: 30px;
    height: @size4;
    min-height: 2px;
    background: #0066dd;
    margin-top: 30px;
  }
  .wrapper {
    display: flex;
    justify-content: center;
    margin-top: @size48;
    .inner {
      width: @size1200;
      min-width: 600px;
      .top {
        cursor: pointer;
        width: @size1200;
        height: @size300;
        background: #ffffff;
        border: 1px solid #999999;
        border-radius: @size10;
        padding-left: 140px;
        position: relative;
        background-image: url(../assets/images/home/business.jpg);
        background-size: cover;
        display: flex;
        justify-content: left; /* 水平居中 */
        align-items: center; /* 垂直居中 */
        overflow: hidden;
        .top-color {
          position: absolute;
          width: @size1200;
          left: 0;
          top: 0;
          height: 300px;
        }
        &:hover {
          .top-color {
            background: linear-gradient(
              to bottom,
              rgba(0, 102, 221, 0),
              80%,
              rgba(0, 102, 221, 0.5)
            ) !important;
          }
          .top-item {
            .top-intro {
              display: block;
            }
          }
        }
        .top-item {
          display: flex;
          justify-content: center; /* 水平居中 */
          align-items: center; /* 垂直居中 */
          flex-direction: column;
          height: 300px;
          .top-first-line {
            display: flex;
            justify-items: left;
            align-items: center;
            img {
              margin-right: @size40;
              width: @size100;
              height: auto;
            }
            .top-title {
              display: inline-block;
              font-size: @size32;
              font-weight: 700;
              text-align: left;
              color: #0066dd;
              line-height: 1.5625;
            }
          }
          .top-intro {
            font-size: @font16;
            font-weight: 400;
            text-align: left;
            color: #444444;
            line-height: 1.5;
            margin-top: @size20;
            display: none;
            width: 500px;
          }
        }

        .more {
          position: absolute;
          opacity: 1;
          font-size: @font18;
          font-weight: 400;
          color: #ffffff;
          bottom: @size20;
          right: @size20;
          cursor: pointer;
        }
      }
      .items {
        margin-top: @size30;
        position: relative;
        width: @size1200;
        min-width: 600px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .item {
          cursor: pointer;
          position: relative;
          width: @size378;
          // width: 580px;
          min-width: 189px;
          height: @size300;
          min-height: 150px;
          background: #ffffff;
          border: 1px solid #999999;
          border-radius: @size10;
          overflow: hidden;
          display: flex;
          justify-content: center; /* 水平居中 */
          align-items: center; /* 垂直居中 */
          flex-direction: column;
          &:hover {
            background: linear-gradient(
              to bottom,
              rgba(0, 102, 221, 0),
              rgba(0, 102, 221, 0.5)
            ) !important;
            .intro {
              display: block;
            }
          }
          .first-line {
            width: 100%;
            height: @size72;
            min-height: 36px;
            text-align: left;
            align-items: center;
            display: flex;
            justify-items: left;
            align-items: center;
            padding-left: @size48;
            img {
              width: @size72;
              min-width: 36px;
              margin-right: @size20;
            }
            .right {
              .line-title {
                font-size: @size32;
                font-weight: 700;
                text-align: left;
                color: #0066dd;
                line-height: 1.5625;
              }
            }
          }
          .intro {
            font-size: @font16;
            font-weight: 400;
            text-align: left;
            color: #444444;
            line-height: 1.5;
            margin-top: @size20;
            padding-left: @size40;
            padding-right: @size40;
            display: none;
          }
          .more {
            font-size: @font18;
            color: #0066dd;
            position: absolute;
            bottom: @size20;
            right: @size20;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
