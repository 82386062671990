<template>
  <div class="news">
    <div class="title">最新动态</div>
    <div class="divider"></div>
    <div class="wrapper">
      <div class="inner">
        <div
          class="item"
          v-for="(item, index) in newsItems"
          :key="index"
          @click="gotoNews(item)"
        >
          <div class="img-wrapper">
            <img :src="item.img" alt="" />
            <div class="news-date">{{ item.date }}</div>
          </div>
          <div class="news-title">{{ item.title }}</div>
          <div class="news-info">{{ item.info }}</div>
        </div>
      </div>
    </div>
    <div class="more-wrapper">
      <div class="more color-button" @click="goto()">查看更多</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeNews',
  props: {
    newsItems: Array,
  },
  methods: {
    goto() {
      this.$router.push({ name: 'News' });
    },
    gotoNews(item) {
      const { id } = item;
      if (id) {
        this.$router.push({ name: 'NewsDetail', params: { id } });
      }
    },
  },
};
</script>
<style scoped lang="less">
.news {
  border-bottom: 1px solid #999;
  width: 100%;
  height: @size910;
  min-height: 455px;
  .title {
    font-size: @size40;
    font-weight: 700;
    text-align: center;
    color: #666;
    margin-top: @size80;
  }
  .divider {
    margin-top: @size30;
    width: @size60;
    min-width: 30px;
    height: @size4;
    min-height: 2px;
    background: #0066dd;
    display: inline-block;
  }

  .wrapper {
    display: flex;
    justify-content: center; /* 水平居中 */
    margin-top: @size80;
    .inner {
      width: @size1200;
      min-width: 600px;
      display: flex;
      justify-content: space-between; /* 水平居中 */
      align-items: center; /* 垂直居中 */
      .item {
        width: @size380;
        min-width: 190px;
        cursor: pointer;
        position: relative;
        &:hover {
          img {
            transform: scale(1.5);
            transition-duration: 3s;
          }
        }
        .img-wrapper {
          width: @size380;
          min-width: 190px;
          height: @size280;
          min-height: 140px;
          overflow: hidden;
          position: relative;
          img {
            width: 100%;
          }
          .news-date {
            position: absolute;
            left: 0;
            bottom: 0;
            min-width: 120px;
            min-height: 22px;
            background-color: rgba(0, 0, 0, 0.5);
            font-size: 18px;
            color: #ffffff;
          }
        }

        .news-title {
          margin-top: @size30;
          margin-bottom: @size30;
          font-size: @size22;
          font-weight: 700;
          text-align: left;
          color: #666666;
        }
        .news-info {
          word-wrap: break-word;
          word-break: break-all;
          text-align: left;
          font-size: @font18;
          color: #444444;
          height: @size70;
          min-height: 35px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          // line-height: 12px;
        }
      }
    }
  }

  .more-wrapper {
    display: flex;
    justify-content: center; /* 水平居中 */
    margin-top: @size114;
    .more {
      width: @size160;
      min-width: 80px;
      height: @size50;
      min-height: 25px;
      background: #0066dd;
      border-radius: @size25;
      text-align: center;
      line-height: @size50;
      font-size: @size20;
      font-weight: 700;
      color: #ffffff;
      cursor: pointer;
    }
  }
}
</style>
