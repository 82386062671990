import news1 from '../assets/images/home/news-1.jpg';
import news2 from '../assets/images/home/news-2.jpg';
import news3 from '../assets/images/home/news-3.jpg';
import detail11 from '../assets/images/service/mine/detail-1-1.jpg';

const newsItem1 = {
  title: '标准体系覆盖多地老旧小区改造',
  info: '全面免工具的标准化部件，从单机到集群再到数据中心级的智能管理系统，为您全面打造简单易用的计算基础设施，实现敏捷的算力输出。',
  date: '2021-07-31',
  img: news1,
  id: '1',
  detail: [
    {
      value: '智擎(Engiant)660是一款兼顾高性能转发和灵活可编程的智能网络处理器；基于弹性可扩展的架构设计，集成256个专用处理器，革命性地支持C语言完全可编程；具有超高集成度，超过180亿晶体管，全球首家集成12路LPDDR5控制器超大DDR带宽；提供高1.2Tbps的接口吞吐能力。 智擎(Engiant)660是一款兼顾高性能转发和灵活可编程的智能网络处理器；基于弹性可扩展的架构设计，集成256个专用处理器，革命性地支持C语言完全可编程；具有超高集成度，超过180亿晶体管，全球首家集成12路LPDDR5控制器超大DDR带宽；提供高1.2Tbps的接口吞吐能力。 智擎(Engiant)660是一款兼顾高性能转发和灵活可编程的智能网络处理器；基于弹性可扩展的架构设计，集成256个专用处理器，革命性地支持C语言完全可编程；具有超高集成度，超过180亿晶体管，全球首家集成12路LPDDR5控制器超大DDR带宽；提供高1.2Tbps的接口吞吐能力。 智擎(Engiant)660是一款兼顾高性能转发和灵活可编程的智能网络处理器；基于弹性可扩展的架构设计，集成256个专用处理器，革命性地支持C语言完全可编程；具有超高集成度，超过180亿晶体管，全球首家集成12路LPDDR5控制器超大DDR带宽；提供高1.2Tbps的接口吞吐能力。 智擎(Engiant)660是一款兼顾高性能转发和灵活可编程的智能网络处理器；基于弹性可扩展的架构设计，集成256个专用处理器，革命性地支持C语言完全可编程；具有超高集成度，超过180亿晶体管，全球首家集成12路LPDDR5控制器超大DDR带宽；提供高1.2Tbps的接口吞吐能力。',
    },
    {
      image: detail11,
    },
    {
      value: '智擎(Engiant)660是一款兼顾高性能转发和灵活可编程的智能网络处理器；基于弹性可扩展的架构设计，集成256个专用处理器，革命性地支持C语言完全可编程；具有超高集成度，超过180亿晶体管，全球首家集成12路LPDDR5控制器超大DDR带宽；提供高1.2Tbps的接口吞吐能力。 智擎(Engiant)660是一款兼顾高性能转发和灵活可编程的智能网络处理器；基于弹性可扩展的架构设计，集成256个专用处理器，革命性地支持C语言完全可编程；具有超高集成度，超过180亿晶体管，全球首家集成12路LPDDR5控制器超大DDR带宽；提供高1.2Tbps的接口吞吐能力。 智擎(Engiant)660是一款兼顾高性能转发和灵活可编程的智能网络处理器；基于弹性可扩展的架构设计，集成256个专用处理器，革命性地支持C语言完全可编程；具有超高集成度，超过180亿晶体管，全球首家集成12路LPDDR5控制器超大DDR带宽；提供高1.2Tbps的接口吞吐能力。 智擎(Engiant)660是一款兼顾高性能转发和灵活可编程的智能网络处理器；基于弹性可扩展的架构设计，集成256个专用处理器，革命性地支持C语言完全可编程；具有超高集成度，超过180亿晶体管，全球首家集成12路LPDDR5控制器超大DDR带宽；提供高1.2Tbps的接口吞吐能力。 智擎(Engiant)660是一款兼顾高性能转发和灵活可编程的智能网络处理器；基于弹性可扩展的架构设计，集成256个专用处理器，革命性地支持C语言完全可编程；具有超高集成度，超过180亿晶体管，全球首家集成12路LPDDR5控制器超大DDR带宽；提供高1.2Tbps的接口吞吐能力。',
    },
  ],
};

const newsItem2 = {
  title: '标准体系覆盖多地老旧小区改造',
  info: '全面免工具的标准化部件，从单机到集群再到数据中心级的智能管理系统，为您全面打造简单易用的计算基础设施，实现敏捷的算力输出。',
  date: '2021-07-31',
  img: news2,
  id: '2',
};

const newsItem3 = {
  title: '标准体系覆盖多地老旧小区改造',
  info: '全面免工具的标准化部件，从单机到集群再到数据中心级的智能管理系统，为您全面打造简单易用的计算基础设施，实现敏捷的算力输出。',
  date: '2021-07-31',
  img: news3,
  id: '3',
};

export default [
  newsItem1,
  newsItem2,
  newsItem3,
  newsItem1,
  newsItem2,
  newsItem3,
  newsItem1,
  newsItem2,
  newsItem3,
  newsItem1,
];
