<template>
  <div class="home">
    <Header theme="black" :currentIndex="headerIndex" />
    <HomePics />
    <HomeAbout />
    <HomeBusiness />
    <HomeService />
    <HomeNews :newsItems="homeNews" />
    <HomePartner style="display:none" />
    <Footer />
    <div class="footer"></div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import HomePics from '@/components/HomePics.vue';
import HomeAbout from '@/components/HomeAbout.vue';
import HomeBusiness from '@/components/HomeBusiness.vue';
import HomeService from '@/components/HomeService.vue';
import HomePartner from '@/components/HomePartner.vue';
import HomeNews from '@/components/HomeNews.vue';
import newsItems from '../utils/news';

export default {
  name: 'Home',
  components: {
    Header,
    Footer,
    HomePics,
    HomeAbout,
    HomeBusiness,
    HomeService,
    HomePartner,
    HomeNews,
  },
  data() {
    return {
      headerIndex: 0,
      homeNews: newsItems.slice(0, 3),
    };
  },
};
</script>
<style lang="less" scoped>
</style>
