import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import { routeUrls } from '../utils/constants';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: routeUrls.about,
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: routeUrls.news,
    name: 'News',
    // route level code-splitting
    // this generates a separate chunk (news.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "news" */ '../views/News.vue'),
  },
  {
    path: `${routeUrls.news}/detail/:id`,
    name: 'NewsDetail',
    component: () => import(/* webpackChunkName: "news" */ '../views/NewsDetail.vue'),
  },
  {
    path: '/solutions',
    redirect: { name: 'SmartMineSolution' },
  },
  {
    path: routeUrls.mineSolution,
    name: 'SmartMineSolution',
    component: () => import(/* webpackChunkName: "service" */ '../views/service/SmartMineSolution.vue'),
  },
  {
    path: `${routeUrls.mineSolution}/detail/:id`,
    name: 'SmartMineSolutionDetail',
    component: () => import(/* webpackChunkName: "service" */ '../views/service/SmartMineSolutionDetail.vue'),
  },
  {
    path: routeUrls.campusSolution,
    name: 'SmartCampusSolution',
    component: () => import(/* webpackChunkName: "service" */ '../views/service/SmartCampusSolution.vue'),
  },
  {
    path: `${routeUrls.campusSolution}/detail/:id`,
    name: 'SmartCampusSolutionDetail',
    component: () => import(/* webpackChunkName: "service" */ '../views/service/SmartCampusSolutionDetail.vue'),
  },
  {
    path: routeUrls.aiSolution,
    name: 'AISolution',
    component: () => import(/* webpackChunkName: "service" */ '../views/service/AISolution.vue'),
  },
  {
    path: `${routeUrls.aiSolution}/detail/:id`,
    name: 'AISolutionDetail',
    component: () => import(/* webpackChunkName: "service" */ '../views/service/AISolutionDetail.vue'),
  },
  {
    path: routeUrls.systemSolution,
    name: 'TrustSystemSolution',
    component: () => import(/* webpackChunkName: "service" */ '../views/service/TrustSystemSolution.vue'),
  },
  {
    path: `${routeUrls.systemSolution}/detail/:id`,
    name: 'TrustSystemSolutionDetail',
    component: () => import(/* webpackChunkName: "service" */ '../views/service/TrustSystemSolutionDetail.vue'),
  },
  {
    path: routeUrls.educationSolution,
    name: 'EducationSolution',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "service" */ '../views/service/EducationSolution.vue'),
  },
  {
    path: routeUrls.mineBusiness,
    name: 'MineRearchCenter',
    component: () => import(/* webpackChunkName: "business" */ '../views/business/MineResearchCenter.vue'),
  },
  {
    path: routeUrls.solutionBusiness,
    name: 'SolutionCenter',
    component: () => import(/* webpackChunkName: "business" */ '../views/business/SolutionCenter.vue'),
  },
  {
    path: routeUrls.consultationBusiness,
    name: 'ConsultationCenter',
    component: () => import(/* webpackChunkName: "business" */ '../views/business/ConsultationCenter.vue'),
  },
  {
    path: routeUrls.educationBusiness,
    name: 'EducationCenter',
    component: () => import(/* webpackChunkName: "business" */ '../views/business/EducationCenter.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

export default router;
