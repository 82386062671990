<template>
  <div class="pics">
    <!-- <div class="pics" :style="style"> -->
    <el-carousel height="9.12rem">
      <el-carousel-item v-for="item in 1" :key="item">
        <div class="item">
          <img
            src="../assets/images/home/home.png"
            alt="home"
            class="home-img"
          />
          <div class="color"></div>
          <div class="pics-content">
            <div class="words">助力矿山行业</div>
            <div class="words lastline">智能化高质量发展</div>
            <div class="words-en">ASSIST MINING INDUSTRY INTELLIGENCE</div>
            <div class="words-en">FOR A HIGH QUALITY DEVELOPMENT</div>
            <div class="pics-btn" @click="goto">了解更多</div>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import { routeUrls } from '../utils/constants';

export default {
  name: 'HomePics',
  props: {},
  // created() {
  //   this.getHeight();
  //   window.addEventListener('resize', this.getHeight);
  // },
  // destroyed() {
  //   window.removeEventListener('resize', this.getHeight);
  // },
  data() {
    return {
      height: 0,
      style: {
        marginTop: '90px',
      },
    };
  },
  methods: {
    getHeight() {
      const width = Math.max(window.innerWidth, 960);
      this.height = `${(912 / 1920) * width}px`;
      this.style.marginTop = `${(90 / 1920) * width}px`;
    },
    goto() {
      this.$router.push({ path: routeUrls.mineBusiness });
    },
  },
};
</script>
<style scoped lang="less">
.pics {
  margin-top: @size90;
  width: 100%;
  position: relative;
  overflow: hidden;
  position: relative;
}
.item {
  position: relative;
  width: 100%;
  img {
    width: 100%;
    height: auto;
  }
  .color {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      to right,
      rgba(0, 102, 221, 1),
      rgba(255, 255, 255, 0)
    );
  }
}
.pics-content {
  position: absolute;
  top: @size200;
  left: @size150;
  color: #fff;
  text-align: left;
  .words {
    font-size: @size80;
    font-weight: 700;
  }
  .lastline {
    margin-bottom: @size40;
  }
  .words-en {
    font-size: @size30;
    font-weight: 700;
  }
  .pics-btn {
    cursor: pointer;
    margin-top: @size90;
    width: @size260;
    min-width: 130px;
    height: @size70;
    min-height: 35px;
    background: #ffffff;
    border-radius: @size35;
    font-size: @size30;
    font-weight: 700;
    color: #0066dd;
    text-align: center;
    line-height: 70px;
  }
}
</style>
